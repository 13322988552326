/* @page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;        
    } 
} */

@media print {
    body {
        background-color: none !important;
        background: #fff !important;

        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    tr.vendorListHeading {
        /* background-color: red !important; */
        print-color-adjust: exact;
    }

    .vendorListHeading th {
        background-color: transparent !important;
    }

    .vendorListHeading td {
        background-color: transparent !important;
    }

    .vendorListHeading3 th,
    .vendorListHeading3 td {
        border: 1px solid var(--gray-color) !important;
    }

    td.border-r-0 {
        border-right: none !important;
    }

    td.border-l-0 {
        border-left: none !important;
    }
}