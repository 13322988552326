.bgLogin {
  background-image: url(../images/bgLogin.jpg);
  /* Full height */
  // height: 100%;
  min-height: 100%;

  height: 100vh;
  width: 100vw;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  // background-size: contain;
  background-size: cover;


  align-items: center;
}

.divLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 700px;
}


@media (min-width: 320px) {
  .divLogin {
    height: 500px;
  }

  .p-5 {
    padding: 1.5rem !important;
  }
}

@media (min-width: 375px) {
  .divLogin {
    height: 500px;
  }

  .p-5 {
    padding: 1.5rem !important;
  }
}

@media (min-width: 425px) {
  .divLogin {
    height: 500px;
  }

  .p-5 {
    padding: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .divLogin {
    height: 500px;
  }

  .p-5 {
    padding: 1.5rem !important;
  }
}

@media (min-width: 1024px) {
  .divLogin {
    height: 500px;
  }

  .p-5 {
    padding: 1.5rem !important;
  }
}

@media (min-width: 1440px) {
  .divLogin {
    height: 700px;
  }

  .p-5 {
    padding: 3rem !important;
  }
}