@font-face {
  font-family: Kalpurush;
  src: url(../fonts/kalpurush.ttf);
  font-stretch: normal, ultra-condensed, extra-condensed, condensed,
    semi-condensed, semi-expanded, expanded, extra-expanded, ultra-expanded;
  font-weight: normal, bold, bolder, lighter, 100, 200, 300, 400, 500, 600, 700,
    800, 900;
  font-style: normal, italic, oblique;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family:  "Roboto", "Kalpurush", sans-serif;
  font-size: var(--font-size-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ---------------- Root CSS Start ---------------- */
:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --third-bg: #f1f4fa;
  --black-bg: #000000;
  --gray-bg: #acacac;

  --main-color: #ffffff;
  --second-color: #fafafb;
  --third-color: #f1f4fa;
  --black-color: #000000;
  --gray-color: #acacac;

  --main-bg-lightblue: #47b5fd;
  --main-bg-lightorange: #fca947;
  --main-bg-darkpurple: #7647fc;
  --main-bg-lightgreen: #21d8b7;
  --main-bg-lightgray: #9a9aa9;
  --main-bg-lightclay: #f0f0f0;
  --main-bg-green: #19813e;
  --main-bg-red: #ed322f;

  --main-color-lightblue: #47b5fd;
  --main-color-lightorange: #fca947;
  --main-color-darkpurple: #7647fc;
  --main-color-lightgreen: #21d8b7;
  --main-color-lightgray: #9a9aa9;
  --main-color-lightclay: #f0f0f0;
  --main-color-green: #19813e;
  --main-color-red: #ed322f;

  --font-size-1: 1px;
  --font-size-2: 2px;
  --font-size-3: 3px;
  --font-size-4: 4px;
  --font-size-5: 5px;
  --font-size-6: 6px;
  --font-size-7: 7px;
  --font-size-8: 8px;
  --font-size-9: 9px;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-17: 17px;
  --font-size-18: 18px;
  --font-size-19: 19px;
  --font-size-20: 20px;
  --font-size-50: 50px;
}

/* ---------------- Root CSS End ---------------- */

/* ---------------- Layout CSS Start ---------------- */

// body {
//   background-color: #F1F4FA;
// }

/* :not(.layout-fixed) .main-sidebar {
  height: inherit;
  min-height: 100%;
  position: absolute;
  top: -55px!important;
  bottom: -20px;
} */

/* :not(.layout-fixed) .main-sidebar {
  position: absolute;
  top: -55px!important;
  bottom: -20px;
} */

:not(.layout-fixed) .main-sidebar {
  min-height: 95% !important;
}

.layout-fixed .main-sidebar {
  height: 95% !important;
}

.layout-navbar-fixed.layout-fixed .wrapper .sidebar {
  margin-top: calc(3.5rem + 50px);
}

.layout-navbar-fixed .wrapper .brand-link {
  top: 30px;
}

.main-header {
  margin: 20px;
  border-radius: 10px;
  position: fixed;
}

.main-sidebar {
  margin: 20px;
  border-radius: 10px;
  bottom: 20px !important;
}

.main-footer {
  margin: 20px;
  border-radius: 10px;
}

body:not(.sidebar-mini-md) .content-wrapper,
body:not(.sidebar-mini-md) .main-footer,
body:not(.sidebar-mini-md) .main-header {
  @media (min-width: 768px) {
    margin-left: 290px;
  }

  @media (max-width: 991.98px) {
    margin-left: 0;
  }
}

.content-wrapper {
  padding-right: 20px;
  padding-top: 30px;
  min-height: 800px !important;

  @media (max-width: 768px) {
    padding-right: 0px;
    padding-top: 30px;
  }
}

.nav-sidebar .nav-item>.nav-link {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .layout-navbar-fixed .wrapper .main-header {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .layout-navbar-fixed .wrapper .main-footer {
    margin-left: 10px;
    margin-right: 10px;
  }
}

// .layout-navbar-fixed.sidebar-mini-md.sidebar-collapse .wrapper .brand-link,
// .layout-navbar-fixed.sidebar-mini.sidebar-collapse .wrapper .brand-link {
//   // height: calc(3.5rem + 40px);
//   // width: 5rem !important;
// }

.nav-sidebar .nav-link>.right,
.nav-sidebar .nav-link>p>.right {
  right: 25px;
}

@media (min-width: 992px) {
  .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p {
    margin-left: 5px !important;
  }
}

@media (min-width: 992px) {

  .sidebar-mini.sidebar-collapse .main-sidebar,
  .sidebar-mini.sidebar-collapse .main-sidebar::before {
    margin-left: 10px;
    width: 5rem;
  }
}

@media (min-width: 992px) {

  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .main-footer,
  .sidebar-mini.sidebar-collapse .main-header {
    // margin-left: 4.6rem!important;
    margin-left: calc(5rem + 20px) !important;
  }
}

@media (min-width: 992px) {
  // .sidebar-mini.sidebar-collapse.layout-fixed .brand-link {
  //   // width: 5rem !important;
  // }
}

/* ---------------- Layout CSS End ---------------- */

/* ---------------- Sidebar Hover Effect Start ---------------- */
.sidebar a, .sidebar span {
  color: var(--main-color-lightgray) !important;
}

.nav-link.active {
  background-color: var(--main-bg-lightblue) !important;
}

.nav-item.menu-open>.nav-link {
  // background-color: var(--main-bg-lightblue) !important;
  // color: var(--main-bg) !important;

  background-color: #edf5fd !important;
  color: var(--main-color-lightblue) !important;

  svg {
    path.iconBGColor {
      // fill: var(--main-bg) !important;
      fill: var(--main-bg-lightblue) !important;
    }
  }
}

.nav-treeview>.nav-item>.nav-link.active {
  background-color: var(--main-bg-lightblue) !important;
  color: var(--main-bg) !important;

  svg {
    path.iconBGColor {
      fill: var(--main-bg) !important;
    }
  }
}

.nav-treeview>.nav-item>.nav-link:hover {
  background-color: var(--main-bg-lightblue) !important;
  color: var(--main-bg) !important;

  svg {
    path.iconBGColor {
      fill: var(--main-bg) !important;
    }
  }
}

.nav-pills>.nav-item>.nav-link.active {
  background-color: var(--main-bg-lightblue) !important;
  color: var(--main-bg) !important;

  svg {
    path.iconBGColor {
      fill: var(--main-bg) !important;
    }
  }
}

.nav-pills>.nav-item>.nav-link:hover {
  background-color: var(--main-bg-lightblue) !important;
  color: var(--main-bg) !important;

  svg {
    path.iconBGColor {
      fill: var(--main-bg) !important;
    }
  }
}

.nav-sidebar .nav-treeview {
  // background-color: #dee2e633 !important;
  background-color: #edf5fd !important;
  font-size: 12px;
  margin-left: 20px;
}

// svg {
//   path.iconBGColor {
//     fill: purple !important;
//   }
// }

// .nav-pills>.nav-item>.nav-link:hover svg {
//   path.iconBGColor {
//     fill: greenyellow !important;
//   }
// }

/* ---------------- Sidebar Hover Effect End ---------------- */

/* ---------------- Common CSS Start ---------------- */
.userImg {
  border-radius: 12%;
  width: 3rem !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.1) !important;
}

.font-1 {
  font-size: var(--font-size-1);
}

.font-2 {
  font-size: var(--font-size-2);
}

.font-3 {
  font-size: var(--font-size-3);
}

.font-4 {
  font-size: var(--font-size-4);
}

.font-5 {
  font-size: var(--font-size-5);
}

.font-6 {
  font-size: var(--font-size-6);
}

.font-7 {
  font-size: var(--font-size-7);
}

.font-8 {
  font-size: var(--font-size-8);
}

.font-9 {
  font-size: var(--font-size-9);
}

.font-10 {
  font-size: var(--font-size-10);
}

.font-11 {
  font-size: var(--font-size-11);
}

.font-12 {
  font-size: var(--font-size-12);
}

.font-13 {
  font-size: var(--font-size-13);
}

.font-14 {
  font-size: var(--font-size-14);
}

.font-15 {
  font-size: var(--font-size-15);
}

.font-16 {
  font-size: var(--font-size-16);
}

.font-17 {
  font-size: var(--font-size-17);
}

.font-18 {
  font-size: var(--font-size-18);
}

.font-19 {
  font-size: var(--font-size-19);
}

.font-20 {
  font-size: var(--font-size-20);
}

.font-50 {
  font-size: var(--font-size-50);
}

.main-bg {
  background-color: var(--main-bg);
}

.second-bg {
  background-color: var(--second-bg);
}

.third-bg {
  background-color: var(--third-bg);
}

.black-bg {
  background-color: var(--black-bg);
}

.gray-bg {
  background-color: var(--gray-bg);
}

.main-color {
  color: var(--main-color);
}

.second-color {
  color: var(--second-color);
}

.third-color {
  color: var(--third-color);
}

.black-color {
  color: var(--black-color);
}

.gray-color {
  color: var(--gray-color);
}

.main-bg-lightblue {
  background-color: var(--main-bg-lightblue);
}

.main-bg-lightorange {
  background-color: var(--main-bg-lightorange);
}

.main-bg-darkpurple {
  background-color: var(--main-bg-darkpurple);
}

.main-bg-lightgreen {
  background-color: var(--main-bg-lightgreen);
}

.main-bg-lightgray {
  background-color: var(--main-bg-lightgray);
}

.main-bg-lightclay {
  background-color: var(--main-bg-lightclay);
}

.main-bg-green {
  background-color: var(--main-bg-green);
}

.main-bg-red {
  background-color: var(--main-bg-red);
}

.main-color-lightblue {
  color: var(--main-color-lightblue);
}

.main-color-lightorange {
  color: var(--main-bg-lightorange);
}

.main-color-darkpurple {
  color: var(--main-color-darkpurple);
}

.main-color-lightgreen {
  color: var(--main-color-lightgreen);
}

.main-color-lightgray {
  color: var(--main-color-lightgray);
}

.main-color-lightclay {
  color: var(--main-color-lightclay);
}

.main-color-green {
  color: var(--main-color-green);
}

.main-color-red {
  color: var(--main-color-red);
}

.font-roboto {
  font-family: "Roboto";
}

.font-montserrat {
  font-family: "Montserrat";
}

.font-poppins {
  font-family: "Poppins";
}

.border-1-lightclay {
  border-bottom: 1px solid var(--main-color-lightclay) !important;
}

.border-b-4-black {
  border-bottom: 4px solid var(--black-color) !important;
}

.border-rl-4-black {
  border-right: 4px solid var(--black-color) !important;
  border-left: 4px solid var(--black-color) !important;
}

.border-black {
  border: 1px solid var(--black-color) !important;
}

.border-gray {
  border: 1px solid var(--gray-color) !important;
}

.border-r-0 {
  border-right: none !important;
}

.border-l-0 {
  border-left: none !important;
}

.border-black thead th {
  vertical-align: bottom;
  border-bottom: 2px solid var(--black-color) !important;
}

.border-black td,
.border-black th {
  border-top: 1px solid var(--black-color) !important;
}

.border-black td,
.border-black th {
  border: 1px solid var(--black-color) !important;
}

.btn-icon {
  display: inline-block;
  font-weight: 400;
  // color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.15rem 0.65rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none !important;
}

.btn-lightblue {
  background-color: var(--main-bg-lightblue);
  color: var(--main-bg);

  &:hover {
    background-color: #009aff;
    color: var(--main-bg);
  }
}

.btn-outline-lightblue {
  color: var(--main-color-lightblue);
  border-color: var(--main-bg-lightblue);

  &:hover {
    background-color: #009aff;
    color: var(--main-bg);
    border-color: var(--main-bg-lightblue);

    svg {
      path.iconBGColor {
        fill: var(--main-bg) !important;
      }

      path.iconBGColors {
        stroke: var(--main-bg) !important;
      }
    }
  }
}

.dropdown-item.active {
  background-color: var(--main-bg);
  color: var(--main-color-lightblue);

  &:hover {
    background-color: #009aff;
    color: var(--main-bg);

    svg {
      path.iconBGColor {
        fill: var(--main-bg) !important;
      }

      path.iconBGColors {
        stroke: var(--main-bg) !important;
      }
    }
  }

  &:active {
    background-color: #009aff;
    color: var(--main-bg);
  }

  &:focus {
    background-color: #009aff;
    color: var(--main-bg);
  }
}

.dropdown-item {
  background-color: var(--main-bg);
  color: var(--main-color-lightblue);

  &:hover {
    background-color: #009aff;
    color: var(--main-bg);

    svg {
      path.iconBGColor {
        fill: var(--main-bg) !important;
      }

      path.iconBGColors {
        stroke: var(--main-bg) !important;
      }
    }
  }

  &:active {
    background-color: #009aff;
    color: var(--main-bg);
  }

  &:focus {
    background-color: #009aff;
    color: var(--main-bg);
  }
}

label {
  color: #4d505d;
}

.form-control {
  background-color: var(--third-bg);
}

.actionTable {
  min-width: 150px !important;
}

.actionTable2 {
  min-width: 120px !important;
}

.actionTable3 {
  min-width: 50px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--third-bg);
}

.table td,
.table th {
  // border-top: 1px solid #FAFAFA;
  border-top: 1px solid #f1f1fa;
  // border-top: none;
}

.table-borderless td,
.table-borderless th {
  // border-top: 1px solid #FAFAFA;
  border-top: none !important;
  // border-top: none;
}

.table-bordered.b-gray-1 td,
.table-bordered.b-gray-1 th {
  border: 1px solid var(--gray-color);
}

.show>.dropdown-menu {
  display: block;
  position: absolute;
}

.no-print {
  display: none;
}

.bgFrames {
  background-image: url(../images/Frame4.png);
  /* Full height */
  // height: 100%;
  min-height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  // background-size: cover;
}

.bgFrame {
  background-image: url(../images/Frame5.png);
  /* Full height */
  // height: 100%;
  min-height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  // background-size: contain;
  background-size: cover;
}

div#example_length {
  font-family: "Roboto";
}

div#example_filter {
  font-family: "Roboto";
}

div#example_info {
  font-family: "Roboto";
}

ul.pagination {
  font-family: "Roboto";
}

.nav-tabs .nav-link {
  border: none;
  color: var(--main-color-lightgray);
  background: none !important;
  outline: none !important;
}

.nav-tabs .nav-link.active {
  background: none !important;
  color: var(--main-color-lightblue);
  border-bottom: 4px solid var(--main-bg-lightblue);
  margin-bottom: -2px;
}

.inputFile {
  border: 1px dashed;
  border-radius: 5px;
  height: 170px;
  width: 170px;
  opacity: 0;

  z-index: 2222;
  position: absolute;
}

.customFile {
  border: 1px dashed var(--main-bg-lightgray);
  border-radius: 15pt;
  height: 170px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--third-bg);
  &:hover{
    opacity: 1;
    background-color: var(--main-bg);
    color: var(--main-bg-lightblue);
    .customFileLebel2 {
        opacity: 1;
        padding: 5pt;
        background-color: var(--main-bg);
        color: var(--main-bg-lightblue);
    }
  }
}

.customFileLebel {
  position: absolute;
  top: 60%;
}

.customFileIcon {
  position: absolute;
  top: 35%;
}
.previewFile{
  border: 1px dashed var(--main-bg-lightgray);
  border-radius: 15pt;
  height: 170px;
  width: 170px;
}
.customFileLebel2 {
  position: absolute;
  top: 60%;
  z-index: 22222;
  opacity: 0;
}
/* ---------------- Common CSS End ---------------- */

/* ---------------- Custom CSS Start ---------------- */
.dark-mode {
  --bg-clr: #000000 !important;
  --main-clr: #ffffff;
  --bg-clr2: #2d2d2d;
  --bdr-clr: #4d4d4d;

  .content-wrapper {
    background-color: var(--bg-clr) !important;
    color: var(--main-clr) !important;
  }

  .main-footer {
    background-color: var(--bg-clr2);
    border-color: var(--bdr-clr);
  }

  .navbar-white {
    background-color: var(--bg-clr2);
    border-color: var(--bdr-clr);
  }

  .navbar-nav .nav-link {
    color: #9a9aa9;

    &:hover {
      color: var(--main-bg-lightblue);
    }

    &:focus {
      color: var(--main-bg-lightblue);
    }
  }

  span.nav-link.text-dark.navHeadA {
    color: var(--main-clr) !important;
  }

  .main-sidebar {
    background-color: var(--bg-clr2);
    border-color: var(--bdr-clr);

    .brand-link {
      background-color: var(--bg-clr2) !important;
      border-color: var(--bdr-clr) !important;
    }

    .border-1-lightclay {
      border-color: var(--bdr-clr) !important;
    }
  }

  .main-bg {
    background-color: var(--bg-clr2);
  }
}

.light-mode {
  --bg-clr: #f1f4fa !important;
  --main-clr: #000000;
}

body {
  background-color: var(--bg-clr) !important;
  color: var(--main-clr) !important;
}

.content-header {
  padding-bottom: 5px;
}

.text-hide-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.circle-icon {
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}


/* Scrollbar Start*/
::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-thumb {
  // background-color: rgba(27, 27, 27, 0.4);
  background-color: var(--main-bg-lightblue);
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* Scrollbar End*/
/* ---------------- Custom CSS End ---------------- */

// .sidebar-mini.layout-fixed.layout-navbar-fixed.sidebar-collapse{
//   .layout-navbar-fixed.layout-fixed .wrapper .sidebar {
//     // margin-top: calc(3.5rem + 50px);
//     margin-top: 2rem !important;
// }
// }



// print page watermark style
.watermark {
  background-color: rgba(255,255,255,0.9);
  background-blend-mode: lighten;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
}
.watermark2 {
  background-color: rgba(255,255,255,0.9);
  background-blend-mode: lighten;
  background-position:  50% 80%;
  background-repeat: no-repeat;
  background-size: 40%;
}