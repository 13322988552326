.textDesn {
    font-style: normal;
    font-weight: 700;
    font-size: 128px;
    line-height: 108.9%;

    background: radial-gradient(50% 86.57% at 50% 50%, rgba(64, 207, 176, 0.8) 0%, rgba(68, 166, 225, 0.8) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        , linear-gradient(89.67deg, #44A6E1 16.34%, #3ACF9D 55.66%, #76DCEA 102.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    /* background-image: linear-gradient(89.67deg, #44A6E1 16.34%, #3ACF9D 55.66%, #76DCEA 102.97%);
    background-size: auto auto;
    background-clip: border-box; */
    background-size: 200% auto;
    /* color: #FFF;
    background-clip: text; */
    animation: animate 10s linear infinite;
}

@keyframes animate {

    /* 0%{
        background-position: 0%;
    }
    100%{
        background-position: 400%;
    } */
    to {
        background-position: 200% center;
    }
}

.bg-min-height{
    min-height: 720px;
}