// @page {
//     size: A4;
//     margin: 0;
// }
// @media print {
//     html, body {
//         width: 210mm;
//         height: 297mm;        
//     } 
// }

tr.vendorListHeadingC {
    /* background-color: red !important; */
    print-color-adjust: exact;
}

.vendorListHeadingC th {
    background-color: #19813E !important;
    color: white !important;
}

.vendorListHeadingC td {
    background-color: transparent !important;
}

@media print {
    body {
        background-color: none !important;
        background: #fff !important;

        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    tr.vendorListHeading {
        /* background-color: red !important; */
        print-color-adjust: exact;
    }

    .vendorListHeading th {
        background-color: transparent !important;
    }

    .vendorListHeading td {
        background-color: transparent !important;
    }


    tr.vendorListHeadingC {
        /* background-color: red !important; */
        print-color-adjust: exact;
    }

    .vendorListHeadingC th {
        background-color: #19813E !important;
        color: white !important;
    }

    .vendorListHeadingC td {
        background-color: transparent !important;
    }

    button#printBtn {
        display: none !important;
    }

    button#closetBtn {
        display: none !important;
    }
}