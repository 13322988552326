.circle-icon {
    min-width: 100px;
    min-height: 100px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.circle-link-lightblue:hover {
    // background-color: var(--main-bg-lightblue);
    background: linear-gradient(0deg, #47B5FD9E, #47B5FD9E), radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, var(--main-bg-lightblue) 100%);
    box-shadow: 0px 0px 37px var(--main-bg-lightblue);

    .main-bg-lightblue {
        background-color: transparent;
    }

    .main-color-lightblue {
        color: var(--main-color);
    }
}

.circle-link-lightblue:focus {
    // background-color: var(--main-bg-lightblue);
    background: linear-gradient(0deg, #47B5FD9E, #47B5FD9E), radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, var(--main-bg-lightblue) 100%);
    box-shadow: 0px 0px 37px var(--main-bg-lightblue);

    .main-bg-lightblue {
        background-color: transparent;
    }

    .main-color-lightblue {
        color: var(--main-color);
    }
}







.circle-link-lightorange:hover {
    // background-color: var(--main-bg-lightorange);
    background: linear-gradient(0deg, #FCA947B3, #FCA947B3), radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, var(--main-bg-lightorange) 100%);
    box-shadow: 0px 0px 37px var(--main-bg-lightorange);

    .main-bg-lightorange {
        background-color: transparent;
    }

    .main-color-lightorange {
        color: var(--main-color);
    }
}

.circle-link-lightorange:focus {
    // background-color: var(--main-bg-lightorange);
    background: linear-gradient(0deg, #FCA947B3, #FCA947B3), radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, var(--main-bg-lightorange) 100%);
    box-shadow: 0px 0px 37px var(--main-bg-lightorange);

    .main-bg-lightorange {
        background-color: transparent;
    }

    .main-color-lightorange {
        color: var(--main-color);
    }
}




.circle-link-darkpurple:hover {
    // background-color: var(--main-bg-darkpurple);
    background: linear-gradient(0deg, #7647FCA6, #7647FCA6), radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, var(--main-bg-darkpurple) 100%);
    box-shadow: 0px 0px 37px var(--main-bg-darkpurple);

    .main-bg-darkpurple {
        background-color: transparent;
    }

    .main-color-darkpurple {
        color: var(--main-color);
    }
}

.circle-link-darkpurple:focus {
    // background-color: var(--main-bg-darkpurple);
    background: linear-gradient(0deg, #7647FCA6, #7647FCA6), radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, var(--main-bg-darkpurple) 100%);
    box-shadow: 0px 0px 37px var(--main-bg-darkpurple);

    .main-bg-darkpurple {
        background-color: transparent;
    }

    .main-color-darkpurple {
        color: var(--main-color);
    }
}








.circle-link-lightgreen:hover {
    // background-color: var(--main-bg-lightgreen);
    background: linear-gradient(0deg, #21D8B7B3, #21D8B7B3), radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, var(--main-bg-lightgreen) 100%);
    box-shadow: 0px 0px 37px var(--main-bg-lightgreen);

    .main-bg-lightgreen {
        background-color: transparent;
    }

    .main-color-lightgreen {
        color: var(--main-color);
    }
}

.circle-link-lightgreen:focus {
    // background-color: var(--main-bg-lightgreen);
    background: linear-gradient(0deg, #21D8B7B3, #21D8B7B3), radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, var(--main-bg-lightgreen) 100%);
    box-shadow: 0px 0px 37px var(--main-bg-lightgreen);

    .main-bg-lightgreen {
        background-color: transparent;
    }

    .main-color-lightgreen {
        color: var(--main-color);
    }
}