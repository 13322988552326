@tailwind base;
@tailwind components;
@tailwind utilities;

// .dark-theme{
//     --main-heading: #5f99be;
//     --para-clr: #fff;
//     --btn-bg-color: #5f99be;
//     --btn-text-clr: #fff;
//     --bg-clr: #000111;
//   }
  
//   .light-theme{
//     --main-heading: #5f99be;
//     --para-clr: #333;
//     --btn-bg-color: #000111;
//     --btn-text-clr: #fff;
//     --bg-clr: #fff;
//   }
  
  
//   h1{
//     color: var(--main-heading);
//   }
  
//   a {
//     text-decoration: none;
//     cursor: pointer;
//     padding: 2px 25px;
//     background-color: var(--btn-bg-color);
//     color: var(--btn-text-clr);
//   }
  
//   li{
//     margin-bottom: 15px;
//   }
  
//   p{
//     color: var(--para-clr);
//   }
  
//   body{
//     padding: 50px!important;
//     background-color: var(--bg-clr);
//   }